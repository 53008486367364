import { render, staticRenderFns } from "./category.page.vue?vue&type=template&id=85cbd748&scoped=true&"
import script from "./category.page.vue?vue&type=script&lang=js&"
export * from "./category.page.vue?vue&type=script&lang=js&"
import style0 from "./category.page.vue?vue&type=style&index=0&id=85cbd748&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85cbd748",
  null
  
)

export default component.exports