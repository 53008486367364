<template>
  <section class="custom-background pb-12 overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ヘッダ直下の画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--<div class="category-detail-page-img-container w-full z-30 relative">-->
        <div class="mx-auto w-8/12 z-30 relative">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ ＰＣのような幅 640 px 以上の画面で表示 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div  class="hidden sm:block">
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="rounded-3xl cursor-pointer"
                :src="category.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ ＰＣのような幅 640 px 以上の画面で表示 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ スマートフォンのような幅 640 px 未満の画面で表示 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div  class="visible sm:hidden">
            <div class="mx-auto w-full z-30 relative">
              <div class="w-full h-full object-scale-down flex items-center justify-center">
                <img
                  class="cursor-pointer"
                  :src="category.banner_url"
                  @click="goToLinkPlace()"
                />
              </div>
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ スマートフォンのような幅 640 px 未満の画面で表示 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ヘッダ直下の画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- 「カテゴリについて」セクションタイトル -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!--
        <div class="description width-1140-no-padding py-2 mx-auto mb-4">
          <div class="title custom-title">
            {{ $t('PROJECT_ABOUT_CATEGORY_SECTION_TITLE') }}
          </div>
        </div>
        -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- カテゴリについてＨＴＭＬテキストで説明 -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <div
              v-if="localizedDescription"
              v-html="$sanitize(localizedDescription)"
              class="width-1140-no-padding py-2 px-4 mx-auto mt-8 mb-4 text-lg text-black description-CSS"
        ></div>
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ お勧めサブカテゴリ一覧表示の場合と、↓ -->
        <!-- ↓ ＮＦＴ一覧表示の場合がある。        ↓ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ show_nftlist＝0 お勧めサブカテゴリの                   ↓ -->
        <!-- ↓   カルーセルセクションタイトル、カルーセル、           ↓ -->
        <!-- ↓   サブカテゴリ一覧セクションタイトル、サブカテゴリ一覧 ↓ -->
        <!-- ↓ お勧めサブカテゴリが無ければ表示しない                 ↓ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <div v-if="category.show_nftlist == 0">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ お勧めサブカテゴリの                       ↓ -->
          <!-- ↓   カルーセルセクションタイトル、カルーセル ↓ -->
          <!-- ↓お勧めサブカテゴリが無ければ表示しない      ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-if="this.recommendedCreators && this.recommendedCreators.length > 0"
          >
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- お勧めサブカテゴリのカルーセルセクセクションタイトル -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <div class="width-1140-no-padding mt-6 py-2 mx-auto custom-background">
              <div class="title custom-title">
                {{ localizedSubCategoryCarouselSectionTitle }}
              </div>
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- お勧めサブカテゴリのカルーセル -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <RecommendedProjectsCarousel
              class="mb-4 width-1140-no-padding mx-auto"
              :data="this.recommendedCreators"
              :numberOfPicture="4"
            ></RecommendedProjectsCarousel>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ お勧めサブカテゴリの                       ↑ -->
          <!-- ↑   カルーセルセクションタイトル、カルーセル ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- サブカテゴリ一覧セクションタイトル -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="width-1140-no-padding mt-6 py-2 mx-auto custom-background">
            <div class="title custom-title">
              {{ localizedSubCategoryListSectionTitle }}
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ サブカテゴリが有る場合、サブカテゴリ一覧１２画像 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-if="creators && creators.length > 0"
            class="mx-auto width-1140"
          >
            <CardListCreator
              @onPageChange="onPageChange"
              :data="creators"
              :numberOfCard="numberOfCreatros"
            ></CardListCreator>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ サブカテゴリが有る場合、サブカテゴリ一覧１２画像 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- サブカテゴリが無い場合 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-else
            class="comingsoon"
          >coming soon..</div>
        </div>
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ show_nftlist＝0 お勧めサブカテゴリの                   ↑ -->
        <!-- ↑   カルーセルセクションタイトル、カルーセル、           ↑ -->
        <!-- ↑   サブカテゴリ一覧セクションタイトル、サブカテゴリ一覧 ↑ -->
        <!-- ↑ お勧めサブカテゴリが無ければ表示しない                 ↑ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ show_nftlist≠0 お勧めサブカテゴリの        ↓ -->
        <!-- ↓   カルーセルセクションタイトル、カルーセル、↓ -->
        <!-- ↓   NFT一覧セクションタイトル、NFT一覧        ↓ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <div v-else>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓お勧めサブカテゴリのセクションタイトルとカルーセル↓ -->
          <!-- ↓お勧めサブカテゴリが無ければ表示しない            ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-if="this.recommendedCreators && this.recommendedCreators.length > 0"
          >
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- お勧めサブカテゴリのカルーセルセクセクションタイトル -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <div class="width-1140-no-padding mt-6 py-2 mx-auto custom-background">
              <div class="title custom-title">
                {{ localizedSubCategoryCarouselSectionTitle }}
              </div>
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- お勧めサブカテゴリのカルーセル -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <RecommendedProjectsCarousel
              class="mb-4 width-1140-no-padding mx-auto"
              :data="this.recommendedCreators"
              :numberOfPicture="4"
            ></RecommendedProjectsCarousel>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ お勧めサブカテゴリのセクションタイトルとカルーセル ↑ -->
          <!-- ↑ お勧めサブカテゴリが無ければ表示しない             ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- 「ＮＦＴ一覧」セクションタイトル -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="width-1140-no-padding mt-6 py-2 mx-auto custom-background">
            <div class="title custom-title">
              {{ $t('PROJECT_NFT_LIST_SECTION_TITLE') }}<!-- ＮＦＴ一覧 -->
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <!-- ↓「ＮＦＴ一覧」１２画像↓ -->
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <div
            class="mx-auto width-1140"
          >
            <CardListNFT
              @onPageChange="onPageChange"
              :data="list"
            ></CardListNFT>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+-----  -->
          <!-- ↑「ＮＦＴ一覧」１２画像↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!-- ↑ show_nftlist≠0 お勧めサブカテゴリの        ↑ -->
        <!-- ↑   カルーセルセクションタイトル、カルーセル、↑ -->
        <!-- ↑   NFT一覧セクションタイトル、NFT一覧        ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ お勧めサブカテゴリ一覧表示の場合と、↑ -->
        <!-- ↑ ＮＦＴ一覧表示の場合がある。        ↑ -->
        <!--  -----+---------+---------+---------+---------+---------+------ -->
      </div>
    </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// １ページに表示する画像数。
import { PICTURE_PER_PAGE } from '@/constants/common.constant';
// プロジェクト（漫画家、アーティスト）一覧表示用。
import CardListCreator from '@/components/creator/CreatorCardList/index.vue';
// NFT一覧表示用。
import CardListNFT from '@/components/nft/CardList/index.vue';
import { mapActions, mapState } from 'vuex';
import RecommendedProjectsCarousel from '@/components/CarouselCreators.vue';
import { getCategories } from '@/services/categories';
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'page-category',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  components: { CardListCreator, CardListNFT, RecommendedProjectsCarousel, },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      category: '',
      recommendedCreators: [],
      creators: [],
      numberOfCreatros: 0,
    }; // End of return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    // ],
  }, // End of metaInfo: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapState({
      // store にセットした、言語モード（日本語／英語）。
      locale: (state) => state?.setting.locale,
      // store にセットした、マーケットプレイスのＮＦＴ情報。
      list: (state) => state.marketplace.marketplaceList,
      //total: (state) => state.marketplace.totalItems || 1,
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }), // End of ...mapState({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 「カテゴリについて」の内容を英語モードと日本語モードで切り替え。
    localizedDescription() {
      const result = (this.locale === 'en'  ? this.category.description_en
                                            : this.category.description_ja
      );
      return result;
    }, // End of localizedDescription() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // カテゴリのサブセクションのカルーセルのセクションタイトルを
    // 英語モードと日本語モードで切り替え。
    localizedSubCategoryCarouselSectionTitle() {
      if (!this.category.carousel_title_en || !this.category.carousel_title_ja){
        // カルーセルセクションタイトルは指定無し。
        return this.$t('PROJECT_RECOMMENDED_CREATOR_SECTION_TITLE')
      }
      // カルーセルセクションタイトルは指定されている。
      return (this.locale === 'en' ? this.category.carousel_title_en
                                   : this.category.carousel_title_ja
      );
    }, // End of localizedSubCategoryCarouselSectionTitle() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // カテゴリのサブセクションの一覧のセクションタイトルを
    // 英語モードと日本語モードで切り替え。
    localizedSubCategoryListSectionTitle() {
      if (!this.category.list_title_en || !this.category.list_title_ja) {
        // 一覧セクションタイトルは指定無し。
        return this.$t('PROJECT_CREATOR_LIST_SECTION_TITLE')
      }
      //  一覧セクションタイトルは指定されている。
      return (this.locale === 'en' ? this.category.list_title_en
                                   : this.category.list_title_ja
      );
    }, // End of localizedSubCategoryListSectionTitle() {...}
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
    // ヘッダのカテゴリ選択コンボボックスと検索テキストボックスを初期化。
    this.clearHeaderCondition();
    // カテゴリ情報取得。
    await this.getCategory(this.id);
    // alert(`this.category=${JSON.stringify(this.category)}: mounted`);
    // プロジェクト一覧の場合とNFT一覧の場合がある。
    if (this.category.show_nftlist == 0) {
      await this.getRecommendedCreators() // お勧めプロジェクト
      await this.getCreators(1); // 引数はページ。お勧め以外も含めたプロジェクト。
    } else {
      await this.getRecommendedCreators() // お勧めプロジェクト
      await this.fetchNewPage(undefined, 1); // カテゴリ直下ＮＦＴ
    } // End of if (this.category.show_nftlist == 0) {...} else {...}
    // パンくずリストをヘッダに書くためのコード。現在未使用。
    this.setHierarchy({hierarchy: '',
                        name_en: this.category.name_en,
                        name_ja: this.category.name_ja,
    }); // End of this.setHierarchy({...})
  }, // End of async mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    ...mapActions({
      // store にある、ヘッダのカテゴリコンボボックスと検索テキストボックスの
      // 値をクリア。
      clearHeaderCondition: 'extractcondition/clearHeaderCondition',
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      setHierarchy: 'hierarchy/setHierarchy',
    }), // End of ...mapActions({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ページが切り替わったら実行。
    async onPageChange(page) {
      if (this.category.show_nftlist == 0) {
        await this.getRecommendedCreators() // お勧めプロジェクト
        await this.getCreators(page); //お勧め以外も含めたプロジェクト。
      } else {
        await this.getRecommendedCreators() // お勧めプロジェクト
        await this.fetchNewPage(undefined, page);
      } // End of if (this.category.show_nftlist == 0) {...} else {...}
    }, // End of async onPageChange(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories から当該ページの
    // カテゴリ情報を一つ取得し this.category に取り込む。内容は一オブジェクト。
    async getCategory(id) {
      const {total: total, items: items} = await getCategories({
        type:  0,
        id  : id,
      });
      if (total==0) {
        // データが取得できなかったらエラーページに遷移。
        await this.$router.push(`/ERROR_PAGE`)
      } 
      this.category = items[0];
      // alert(`*** this.category=${JSON.stringify(this.category)}: getCategory(id=${id}) ***`);
    }, // End of async getCategory(id) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル creators から、当該ページの
    // カテゴリに紐づく『お勧めの』クリエイターの情報を全て取得し、
    // this.recommendedCreators に取り込む。
    // 内容は一レコード一オブジェクトの配列。
    async getRecommendedCreators() {
      const objectAboutCreator = {
        type: 1, // テーブル categories のクリエイターレコードを指定。
        hierarchy: this.category.name_en,
        recommendation_categorytop_as_project: 1, // お勧めの creator のみ取得
        is_deleted: 0, // 論理削除されていない creator のみ取得
      }; // End of const objectAboutCreator = {...}
      // +---------+---------+---------+---------+---------+---------+---------E
      const {items:items} = await getCategories(objectAboutCreator);
      this.recommendedCreators = items;
    }, // async getRecommendedCreators() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル creators から、当該ページの
    // カテゴリに紐づくクリエイターの情報（お勧めもお勧め以外も）を一ページ分
    // 取得し this.creators に取り込む。内容は一レコード一オブジェクトの配列。
    // 同時に、条件を満たす全レコード数も取得（Pagenationに用いる）。
    async getCreators(page) {
      const objectAboutCreator = {
        type: 1, // テーブル categories のクリエイターレコードを指定。
        limit: PICTURE_PER_PAGE, // 取得クリエイターの人数。現在 12。
        offset: PICTURE_PER_PAGE * (page - 1), // １ページなら0、２ページなら12。
        hierarchy: this.category.name_en,
        is_deleted: 0, // 論理削除されていない creator のみ取得。
      }; // End of const objectAboutCreator = {...}
      // +---------+---------+---------+---------+---------+---------+---------E
      const {total:total,items:items} = await getCategories(objectAboutCreator);
      this.numberOfCreatros = total;
      //alert(`this.numberOfCreators=${this.numberOfCreatros}`)
      this.creators = items;
    }, // End of async getCreators(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async fetchNewPage(status, page) {
      this.loading = true;
      const offset = PICTURE_PER_PAGE * (page - 1);
      await this.fetchMarketplace({
        limit: PICTURE_PER_PAGE,
        offset,
        //status: status === 'ALL' ? undefined : status,
        //hierarchy: this.category.hierarchy,
        // '\' は特殊文字で '\\' としないとうまくいかない。
        hierarchy: JSON.stringify([
          this.category.name_en,
          this.category.name_en + '\\%',
        ]),
      }); // End of await this.fetchMarketplace({...})
      this.loading = false;
    }, // End of async fetchNewPage(status, page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // this.category.link の URL を開く。一番上の画面をクリックしたときの処理。
    goToLinkPlace() {
      // window.open(this.category.link, '_blank');
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>

/* .category-detail-page-img-container { */
/*   width: 1140px; */
/*   @apply mx-auto; */
/* } */
::v-deep .category-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}


.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}
.width-1140-no-padding {
  @screen xl {
    width: 1140px;
  }
}
.width-1240-no-padding {
  @screen xl {
    width: 1240px;
  }
}
.custom-background {
    background-color: white;
}
/* タイトル用の基本スタイル */
.title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  /* color: #FFFFFF; テキストカラー 白 */
  color: #000000; /* テキストカラー 黒 */
  margin-bottom: 20px; /* 下余白 */
  padding: 10px 10px; /* 上下パディング */
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}
 .comingsoon {
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  color:black;
  font-size:24px;
 }

.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
::v-deep .ant-select {
  color: #fff;
  @apply font-semibold;
  &-arrow {
    color: white;
  }
  &-selection {
    background-color: transparent;
    border: 1px solid white;
    @apply rounded-xl;
    @apply w-32 h-6;
    @apply text-xs;
    &__rendered {
      @apply mx-0;
      @apply w-full h-full;
      .ant-select-selection-selected-value {
        @apply w-full h-full text-center relative -top-1;
      }
    }
  }
}
/*以下カテゴリ紹介文内のCSSに適用 */
::v-deep .description-CSS p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS a > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

@media (max-width: 768px) {
  ::v-deep .description-CSS p {
    max-width: 100%;
  }
}
</style>
